import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { AnimationControls, AnimationDefinition } from 'framer-motion';
import { debounce } from 'lodash';
import { useInterval } from '@sqior/react/hooks';

// Define the context shape
interface AnimationContextType {
  controlAnimations: ControlAnimations[];
  addControlAnimation: (controlAnimation: ControlAnimations) => void;
  removeControlAnimation: (_id: string) => void;
}

// Create the context
const AnimationContext = createContext<AnimationContextType | undefined>(undefined);

// Context provider component
interface AnimationProviderProps {
  children: ReactNode;
}

export type ControlAnimations = {
  _id: string;
  definition: AnimationDefinition;
  controls: AnimationControls;
};

const animationClc = 6000;

export const AnimationProvider: React.FC<AnimationProviderProps> = ({ children }) => {
  const firstTimeAnimation = useRef<Date>();
  const [controlAnimations, setControlAnimations] = useState<ControlAnimations[]>([]);
  const [update, setUpdate] = useState(false);

  const addControlAnimation = (controlAnimation: ControlAnimations) => {
    const _id = controlAnimation._id;

    setControlAnimations((prev) => {
      const cloned = [...prev];
      const index = cloned.findIndex((item) => item._id === _id);
      if (index > -1) {
        cloned.splice(index, 1, controlAnimation);
      } else {
        cloned.push(controlAnimation);
      }
      return cloned;
    });
  };

  const removeControlAnimation = (_id: string) => {
    setControlAnimations((prev) => prev.filter((item) => item._id !== _id));
  };

  const play = debounce(() => {
    for (const control of controlAnimations) {
      control.controls.start(control.definition);
    }
  });

  useEffect(() => {
    const startAnimation = () => {
      if (controlAnimations.length === 0) return;
      if (!firstTimeAnimation.current) {
        firstTimeAnimation.current = new Date();
        play();
        setUpdate(true);
      }
    };

    startAnimation();
  }, [controlAnimations, setUpdate]);

  useInterval(
    () => {
      play();
    },
    update ? animationClc : null
  );

  return (
    <AnimationContext.Provider
      value={{ addControlAnimation, controlAnimations, removeControlAnimation }}
    >
      {children}
    </AnimationContext.Provider>
  );
};

// Hook to use the animation context
export const useSyncAnimation = (): AnimationContextType => {
  const context = useContext(AnimationContext);
  if (context === undefined) {
    throw new Error('useAnimationContext must be used within an AnimationProvider');
  }
  return context;
};
