import { Emitter } from '@sqior/js/event';
import styles from './qrscanner-operation-control.module.css';
import QRScannerControl, { FacingMode } from '../qrscanner/qrscanner';
import { useContext } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { AuthContext } from '@sqior/react/uiauth';
import { AddOperation, OperationState } from '@sqior/js/operation';
import { ExternalPromise } from '@sqior/js/async';
import { Value, ValueOrNothing } from '@sqior/js/data';
import { Logger } from '@sqior/js/log';

export interface QRScannerOperationControlProps {
  takePhoto?: Emitter;
  onPhoto?: (canv: HTMLCanvasElement) => void;
  facing?: FacingMode;
  onFacing?: (facing: FacingMode) => void;
  onClose?: () => void;
  operationCreator: (code: string) => { op: AddOperation; path: string };
}

export function QRScannerOperationControl(props: QRScannerOperationControlProps) {
  const dispatcher = useContext(OperationContext);
  const authContext = useContext(AuthContext);

  const onStateChange = (
    state: OperationState,
    addOperation: AddOperation,
    res: ExternalPromise<ValueOrNothing>
  ) => {
    switch (state) {
      case OperationState.Failed:
        res.resolve(undefined);
        break;
      case OperationState.Completed:
        res.resolve(addOperation.finalId ?? '');
        break;
      default:
        break;
    }
  };

  return (
    <QRScannerControl
      takePhoto={props.takePhoto}
      onPhoto={props.onPhoto}
      facing={props.facing}
      onFacing={props.onFacing}
      onDetected={(code: string) => {
        const { op, path } = props.operationCreator(code);
        const prom = new ExternalPromise<ValueOrNothing>();
        op.stateChange.on((state) => {
          onStateChange(state, op, prom);
        });
        dispatcher.handle(op, path);
        return prom.promise;
      }}
      onClose={(data: Value) => {
        if (data) {
          Logger.info(`QR code returned user, relogin with ${data}`);
          authContext.provider.tryLogIn(data as string);
        }
        props.onClose?.();
      }}
    />
  );
}
/*
const { op, path } = props.operationCreator?.(code) ?? {
  op: new AddOperation(code),
  path: 'qr-code',
};
*/
export default QRScannerOperationControl;
