import { LanguageTextResourceMap } from '@sqior/js/language';

/** App text resources for the client */
export const AppClientTextResources: LanguageTextResourceMap = {
  de: {
    qr_code_scan: 'QR-Code scannen',
    qr_code_scan_description:
      'Du kannst QR-Codes von Patientenarmbändern, Räumen und Mitarbeiterausweisen scannen.',
    add_paired_device: 'Neues Gerät hinzufügen',
    remove_paired_device: 'Gerät entfernen',
    device_last_connect: 'Letzte Verbindung',
    paired_devices: 'Verknüpfte Geräte',
    authentication_error: 'Fehler bei der Authentifizierung',
    cancel: 'Abbrechen',
    change_profile_picture: 'Profilbild ändern',
    change_roles: 'Rollen wechseln',
    change_room: 'Raum wechseln',
    change_specialty: 'Abteilung wechseln',
    change_user: 'Nutzer wechseln',
    cannot_establish_connection: 'Kann die Verbindung zum Server nicht aufbauen',
    chat_participants: 'Mitglieder',
    check_back: 'Rückfrage',
    check_connection: 'Bitte überprüfe deine Netzwerkverbindung und verbinde dich dann erneut.',
    command_history: 'Letzte Aktionen',
    connect: 'Verbinden',
    close: 'Schließen',
    connection_lost:
      'Die Verbindung zum Server wurde unterbrochen. Es können keine Nachrichten gesendet oder empfangen werden.',
    confirm: 'Bestätigen',
    cut_profile_picture: 'Profilbild zuschneiden',
    data_protection: 'Datenschutz',
    demo_layout: 'Demoansicht',
    dynamic_role_assignment: 'Dynamische Rollenzuordnung',
    enter_phone_number: 'Telefonnummer eintragen',
    enter_phone_number_description:
      "Bitte trage hier die Nummer <span class='text-highlight'>dieses Geräts</span> ein. So können deine Kollegen dich einfacher erreichen. Du kannst die Nummer nachträglich ändern.",
    enter_phone_number_placeholder: '+49 123 456789',
    fixed_push_roles_assignment: 'Push-Nachrichten für',
    fixed_roles_assignment: 'Feste Rollenzuordnung für dieses Gerät',
    fixed_room_assignment: 'Feste Raumzuordnung für dieses Gerät',
    fixed_specialties_assignment: 'Feste Abteilungszuordnung für dieses Gerät',
    forward_grant_permission: 'Freigabe einholen',
    grant_permission: 'Freigeben',
    grant_permission_notification: 'Freigabe für Browser-Benachrichtigungen erforderlich:',
    help_and_support: 'Hilfe & Support',
    support_chats: 'Support Chats',
    new_request: 'Neue Anfrage',
    impressum: 'Impressum',
    input_search_term: 'Suchbegriff eingeben',
    input_search_chat_partner: 'Nachname oder Rolle suchen',
    later: 'Später',
    log_out: 'Abmelden',
    mark_read: 'Als gelesen markieren',
    new_app_version: 'Eine neue Version dieser App ist nun im App Store verfügbar!',
    no_entries: 'Aktuell keine Einträge',
    no_chats: 'keine Chats vorhanden',
    no_dynamic_role: 'keine Rolle ausgewählt',
    no_fixed_location: 'keine feste Zuordnung',
    no_fixed_push_roles: 'keine Rolle ausgewählt',
    no_fixed_roles: 'keine feste Zuordnung',
    no_selection: 'keine Auswahl',
    occupancy_team: 'Belegteam',
    or_team: 'Kolleginnen kontaktieren',
    or_team_short: 'Kolleginnen',
    bar_code: 'Barcode',
    order: 'Beauftragen',
    percentage_uploaded: '% hochgeladen',
    permanent_settings: 'Dauerhafte Einstellungen',
    permanent_settings_admin: 'Admin-Mode',
    priority: 'Priorität',
    processing_image: 'Bild wird verarbeitet',
    reply_to: 'Antworten',
    qr_room_title: 'Raum auswählen',
    qr_room_description:
      'Hier kannst du die Räume auswählen, für die du zuständig bist. Die Benachrichtigungen, die du erhältst, werden auf diese Räume begrenzt. Du kannst auch mehrere Räume auswählen.',
    qr_room_current_rooms: 'Aktuelle Räume',
    qr_room_add_room: 'Raum hinzufügen',
    qr_user_title: 'Nutzer wechseln',
    qr_user_description:
      "Scanne den <span class='text-highlight'>QR-Code vom Mitarbeiterausweis</span> um dich schnell mit deiner Identität als Nutzer anzumelden.",
    restart_server: 'Server neustarten',
    restart_server_confirm: 'Neustart bestätigen',
    revert: 'Rückgängig',
    select_chat_partner: 'Gesprächspartner auswählen',
    settings: 'Einstellungen',
    setting_phonenumber_device: 'Geräte-Telefonnummer',
    setting_phonenumber_user: 'Nutzer-Telefonnummer',
    specialty_selection_title: 'Abteilung auswählen',
    specialty_selection_description:
      'Hier kannst du die Abteilung auswählen, der du angehörst oder für die du zuständig bist. Du kannst auch mehrere Abteilungen auswählen.',
    support_infos: 'Informationen für den Support:',
    support_infos_versions: 'Versionen',
    support_infos_deployment: 'Deployment',
    support_infos_licenses: 'Lizenzen',
    support_infos_logs: 'Logeinträge',
    support_infos_server_version: 'Server:',
    support_infos_server_time: 'Server Zeit:',
    support_infos_wep_version: 'Web-Client:',
    support_infos_native_version: 'Native-Client:',
    support_infos_deployment_url: 'Addresse:',
    sqior_contact: 'sqior Kontakt',
    sqior_contact_description: 'Bei Problemen, Fragen oder Feedback zur App',

    questions_faq: 'Häufig gestellte Fragen',
    support_chat_open: 'Support Chat öffnen',

    hospital_contact: 'Klinik Kontakt',
    hospital_contact_description:
      'Bei Fragen zum Smartphone oder zur lokalen Internetverbindung setzen Sie sich bitte mit der Support-Abteilung der Klinik in Verbindung.',

    take_photo: 'Aufnehmen',
    take_profile_picture: 'Profilbild aufnehmen',
    take_over: 'Übernehmen',

    information_messages: 'Aktualisierungen & Ereignisse',

    super_user: 'Super-Benutzer',

    tab_task_label: 'Aufgaben',
    tab_list_label: 'Übersicht',
    tab_chat_label: 'Chat',
    tab_dashboard: 'Analyse',

    upload_file_label: 'Datei hochladen',

    button_start_group_chat: 'Gruppenchat starten',
    next: 'Weiter',
    group_chat_title_selection: 'Gruppenname festlegen',
    group_chat_title_selection_description:
      'Du kannst hier einen Namen für deine Chatgruppe festlegen.',
    automatic_onboarding: 'Automatische Einschleusung',
    priority_group: 'Prioritätengruppe',
    settings_slogan_title: 'Tagessprüche anzeigen',
    unknown_user: 'Unbekannt',
    late_registrations: 'Nachmeldungen',
    one_late_registration: 'eine Nachmeldung',
    no_late_registrations: 'Keine Nachmeldungen',
    discharge_today: 'Entlassung Heute',
    will_be_free: 'Wird Frei',
    will_be_blocked: 'Wird Blockiert',
    occupancy_management: 'Belegungsmanagement',
    ope_management: 'OP-Management',
    patients: 'Patienten',
    compliance: 'Compliance',
    support_contact: 'Support kontaktieren',
    create: 'Erstellen',
    problem_description: 'Kurzer anonymer Betreff',
    choose_category: 'Eine Kategorie wählen',
    new_support_chat_privacy:
      'Personenbezogene Daten im Betreff sind zu vermeiden, da diese extern gespeichert werden können.',
    scan_qr_code: 'Scanne QR-Code',
    qr_code_scan_description_pairing_1:
      'Öffne die sqior App auf deinem Smartphone und scanne den QR-Code.',
    qr_code_scan_description_pairing_2:
      'Stelle sicher, dass dein Smartphone nicht bereits verknüpft ist und als Zentrale diese Adresse eingetragen ist:<br /><em>{publicServer}{publicServerCustomer}/</em>',
    qr_code_problem: 'Probleme beim Verbinden?',
    contact_us: 'Kontaktiere uns',
    active: 'Aktiv',
    inactive: 'Inaktiv',
    device_reconnect_necessary: 'Erneutes Verbinden notwendig',
    server_information: 'Server Informationen',
    blocked_patients: 'Patienten gesperrt',
    not_today_description: 'Der gewählte Tag is nicht heute',
  },
};
