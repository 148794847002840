import { OperatedORsTelemetryPath } from '@sqior/viewmodels/telemetry';
import TelemetryViewArea from '../telemetry-view-area/telemetry-view-area';
import styles from './operated-ors-telemetry-view.module.css';

/* eslint-disable-next-line */
export interface OperatedORsTelemetryViewProps {}

export function OperatedORsTelemetryView(props: OperatedORsTelemetryViewProps) {
  return <TelemetryViewArea path={OperatedORsTelemetryPath} title="operated_ors" />;
}

export default OperatedORsTelemetryView;
