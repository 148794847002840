import { AuthProvider, BearerTokenAuthContext, UserInfoType } from '@sqior/js/authbase';
import { ClockTimestamp, now, secondsNow } from '@sqior/js/data';
import { Logger } from '@sqior/js/log';
import { jwtDecode } from 'jwt-decode';
import { SignedToken } from '@sqior/viewmodels/app';
import { AddOperation } from '@sqior/js/operation';
import { KeyExpirationState } from '@sqior/js/crypto-definitions';

export class PublicAuthProvider extends BearerTokenAuthContext implements AuthProvider {
  constructor(token: string, sessionStart = now()) {
    super();
    this.token = token;

    this.sessionStart = sessionStart;
    this.initialized = false;
  }

  override async getIdentityToken(): Promise<string | undefined> {
    return undefined;
  }

  async generateToken(scope = '') {
    Logger.info('generating token');
    const token = PublicAuthProvider.storage.getItem('pairingToken') ?? this.token;
    return { token, sessionStart: this.sessionStart };
  }

  tokenStatus() {
    const token = PublicAuthProvider.storage.getItem('pairingToken');
    if (token) {
      const decoded = jwtDecode(token) as SignedToken;
      const moment = secondsNow();
      const beginGrace = decoded.exp - decoded.grace;
      if (moment < beginGrace) {
        Logger.debug(['Nothing to do for', decoded.name]);
        return KeyExpirationState.Valid;
      }
      if (moment > decoded.exp) {
        Logger.info(['Token for', decoded.sub, 'expired, redirecting to pairing screen']);
        return KeyExpirationState.Expired;
      }
      if (moment > beginGrace) {
        Logger.info(['Token for', decoded.sub, 'needs refreshing']);
        return KeyExpirationState.InGracePeriod;
      }
    }
    return KeyExpirationState.NotFound;
  }

  tryLogIn(user?: string | undefined): void {
    if (this.tokenStatus() < 2) {
      const decoded = jwtDecode(this.token) as SignedToken;
      this.userInfo.name = decoded.name;
      this.userInfo.userId = decoded.sub;
    }
    Logger.info('Attempt to log in');
  }

  async refreshToken(operation: AddOperation) {
    await operation.completion();
    const newToken = operation.finalId;
    if (newToken) {
      PublicAuthProvider.storage.setItem('pairingToken', newToken);
      Logger.info('Token Refresh successful');
    } else {
      Logger.warn('Could not refresh token. Redirecting to login');
      this.logOut();
    }
  }

  logOut(): void {
    PublicAuthProvider.storage.removeItem('pairingToken');
    Logger.info('Logging out');
    window.location.reload();
  }

  static get storage() {
    return window.localStorage ?? window.sessionStorage;
  }

  authFailedReloadPeriod(): ClockTimestamp {
    return 0;
  }

  public initialized: boolean;
  private readonly token: string;
  userInfo: UserInfoType = { name: 'anonymous' };
  readonly isAuthenticated = new Promise<boolean>((resolve) => resolve(true));
  private readonly sessionStart?: ClockTimestamp;
}
