import './App.css';
import {
  AppFrame,
  AuthenticationSystem,
  createUIAppContext,
  isSqiorMobileClient,
} from '@sqior/react/uiapp';

import { VersionInfo } from '@sqior/react/utils';
import { UIBasePlugin } from '@sqior/react/uibase';
import { TelemetryClientTextResources, TelemetryViewSelection } from '@sqior/react/uitelemetry';
import { ReactComponent as Logo } from './sqior-logo-svg-white.svg';

/* Define the context */
const context = createUIAppContext('telemetry', TelemetryClientTextResources);
/* Initialize plug-ins */
context.plugins.require(UIBasePlugin, context);

/** Main rendering function of the app */
function App() {
  const versionInfo: VersionInfo = {
    version: process.env.REACT_APP_VERSION || 'Environment variable REACT_APP_VERSION missing',
    appnameInternal: process.env.REACT_APP_NAME || 'Environment variable REACT_APP_NAME missing',
    appname: 'sqior Telemetry Viewer',
    mobileClient: isSqiorMobileClient(),
  };

  return (
    <AppFrame config={{ core: context, auth: AuthenticationSystem.OAuth }} version={versionInfo}>
      <Logo className="logo" />
      <TelemetryViewSelection />
    </AppFrame>
  );
}

export default App;
