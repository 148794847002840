import SessionTelemetryView from '../session-telemetry-view/session-telemetry-view';
import { SelectControl, SelectModel, useTextResources } from '@sqior/react/uibase';
import styles from './telemetry-view-selection.module.css';
import { useState } from 'react';
import MessageTelemetryView from '../message-telemetry-view/message-telemetry-view';
import ORWorkflowTelemetryView from '../orworkflow-telemetry-view/orworkflow-telemetry-view';
import TaskComplianceView from '../task-compliance-view/task-compliance-view';
import OperatedORsTelemetryView from '../operated-ors-telemetry-view/operated-ors-telemetry-view';

/* eslint-disable-next-line */
export interface TelemetryViewSelectionProps {}

export function TelemetryViewSelection(props: TelemetryViewSelectionProps) {
  const textDict = useTextResources();
  const [selected, setSelect] = useState(0);
  const data: SelectModel = {
    items: ['users', 'procedures', 'messages', 'ors', 'task_compliance'].map((str) =>
      textDict.get(str)
    ),
    selected,
  };
  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <SelectControl
          className={styles['select']}
          data={data}
          onSelect={setSelect}
        ></SelectControl>
      </div>
      {selected === 0 && <SessionTelemetryView />}
      {selected === 1 && <ORWorkflowTelemetryView />}
      {selected === 2 && <MessageTelemetryView />}
      {selected === 3 && <OperatedORsTelemetryView />}
      {selected === 4 && <TaskComplianceView />}
    </div>
  );
}

export default TelemetryViewSelection;
