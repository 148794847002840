import { SessionTelemetryPath } from '@sqior/viewmodels/telemetry';
import TelemetryViewArea from '../telemetry-view-area/telemetry-view-area';

/* eslint-disable-next-line */
export interface SessionTelemetryViewProps {}

export function SessionTelemetryView(props: SessionTelemetryViewProps) {
  return <TelemetryViewArea path={SessionTelemetryPath} title="users_by_day" />;
}

export default SessionTelemetryView;
