import { StateContext } from '../state-context';
import { useContext, useEffect } from 'react';
import { useStateOnce } from '@sqior/react/hooks';
import { Value, isEqual } from '@sqior/js/data';
import { State } from '@sqior/js/state';

/** Helper hook function registering the use of a state */
function useSubState(state: State) {
  /* Increment and release the use count on the sub-state */
  useEffect(() => {
    /* Increase use count */
    state.use();
    /* Return a clean-up method that drops the use */
    return () => {
      state.dropUse();
    };
  }, [state]);
}

/** Hook type function that provides the state value and keeps it up-to-date */
export function useDynamicStateRaw<Type extends Value>(path: string) {
  /* Get the context state object - usually this is defined top-level */
  const stateContext = useContext(StateContext);

  /* Get Initial value from state */
  const stateObj = stateContext.subState(path);
  const lastState = stateObj.getRaw<Type>();
  /* Initialize the state variable which is actually manipulated via the provided setter function in the useEffect() below */
  const [value, setValue] = useStateOnce<Type | undefined>(() => lastState);

  /* Use an effect hook with clean-up to listen and stop listening to state changes */
  useEffect(() => {
    /* Register a listener and update value on change */
    const stopListening = stateObj.onTyped<Type>((val) => {
      setValue(val);
    });

    // Since useEffect() is called asynchronously, re-get the value and set to state if different
    const newValue = stateObj.getRaw<Type>();
    if (!isEqual(value, newValue)) setValue(newValue);

    /* Return a clean-up method that removes the listener */
    return () => {
      stopListening();
    };
    /* eslint-disable-next-line */
  }, [setValue, stateObj]);

  /* Register the use of the sub-state */
  useSubState(stateObj);

  return value;
}

/** Helper function to use the substate value */
function useSubstateValue<Type extends Value>(stateObj: State, defValue: Type) {
  const lastState = stateObj.get<Type>(defValue);
  /* Initialize the state variable which is actually manipulated via the provided setter function in the useEffect() below */
  const [value, setValue] = useStateOnce<Type>(() => lastState);

  /* Use an effect hook with *clean-up* to listen and stop listening to state changes */
  useEffect(() => {
    /* Register a listener and update value on change */
    const stopListening = stateObj.onTyped<Type>(() => {
      setValue(stateObj.get<Type>(defValue));
    });

    // Since useEffect() is called asynchronously, re-get the value and set to state if different
    const newValue = stateObj.get<Type>(defValue);
    if (!isEqual(value, newValue)) setValue(newValue);

    /* Return a clean-up method that removes the listener */
    return () => {
      stopListening();
    };
    /* eslint-disable-next-line */
  }, [defValue, stateObj, setValue]);

  return value;
}

/** Hook type function that provides the state value and keeps it up-to-date */
export function useDynamicStateIfAvailable<Type extends Value>(path: string, defValue: Type) {
  /* Get the context state object - usually this is defined top-level */
  const stateContext = useContext(StateContext);
  /* Get state object */
  const stateObj = stateContext.subState(path);
  /* Use it */
  return useSubstateValue(stateObj, defValue);
}

/** Hook type function that provides the state value and keeps it up-to-date */
export function useDynamicState<Type extends Value>(path: string, defValue: Type) {
  /* Get the context state object - usually this is defined top-level */
  const stateContext = useContext(StateContext);
  /* Get state object */
  const stateObj = stateContext.subState(path);
  /* Use it */
  const value = useSubstateValue(stateObj, defValue);

  /* Register the use of the sub-state */
  useSubState(stateObj);

  return value;
}
export default useDynamicState;
