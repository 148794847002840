import { AuthConfig } from '@sqior/js/authbase';
import { Emitter } from '@sqior/js/event';
import { joinUrlPath } from '@sqior/js/url';
import { AuthConfirmTriggerType, AuthContext } from '@sqior/react/uiauth';
import { ConfigContext } from '@sqior/react/utils';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { KeycloakProvider } from '../keycloak-provider';
import { OAuthProviderBase } from '../oauth-provider-base';
import { OpenIdProvider } from '../openid-provider';

/* eslint-disable-next-line */
export interface OAuthWrapperProps {
  authProvider?: OAuthProviderBase;
  scopes?: string[];
  children: ReactNode;
  authConfig?: AuthConfig;
}

export function OAuthWrapper(props: OAuthWrapperProps) {
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState('');
  const configContext = useContext(ConfigContext);
  const config = props.authConfig;
  const configUrl = configContext.getEndpoint('config');
  const [authProvider, setAuthProvider] = useState<OAuthProviderBase | undefined>(undefined);
  useEffect(() => {
    if (!config) return;
    if (
      config.sqiorAuthBaseUrl &&
      config.sqiorAuthClientId &&
      config.sqiorAuthUrl &&
      config.baseUrlPrefix
    ) {
      const appUrl = joinUrlPath(window.location.origin, config.baseUrlPrefix);

      const client =
        props.authProvider ??
        (config.sqiorAuthRealm ? new KeycloakProvider() : new OpenIdProvider());
      client.init(config, appUrl);
      setInitialized(true);
      setError('');
      setAuthProvider(client);
    } else setError(`Missing mandatory parameters in: ${configUrl}`);
  }, [config]);

  if (authProvider) {
    return (
      <AuthContext.Provider
        value={{
          provider: authProvider,
          confirmIdentity: new Emitter<AuthConfirmTriggerType>(),
        }}
      >
        {initialized && props.children}
        {error && error}
      </AuthContext.Provider>
    );
  }
  return <div></div>;
}

export default OAuthWrapper;
