import { TaskComplianceTelemetryPath } from '@sqior/viewmodels/telemetry';
import TelemetryViewArea from '../telemetry-view-area/telemetry-view-area';
import styles from './task-compliance-view.module.css';

/* eslint-disable-next-line */
export interface TaskComplianceViewProps {}

export function TaskComplianceView(props: TaskComplianceViewProps) {
  return (
    <TelemetryViewArea
      path={TaskComplianceTelemetryPath}
      title="task_compliance_by_day"
      type="scatter"
      values={[
        { key: 'Surgeon', label: 'surgeon', color: '#1cade4' },
        { key: 'ORNurse', label: 'or_nurse', color: '#13cd8a' },
        { key: 'AnesthesiaNurse', label: 'anesthesia_nurse', color: '#e9b80e' },
        { key: 'Anesthesist', label: 'anesthesist', color: '#cd3c87' },
        { key: 'ORDisponent', label: 'or_disponent', color: '#7decd7' },
        { key: 'SDSEmployee', label: 'sds_nurse', color: '#ad3c17' },
        { key: 'Holding', label: 'holding_nurse', color: '#dd5c97' },
        { key: 'NeuromonitoringEmployee', label: 'neuromonitoring', color: '#ad2ce7' },
        { key: 'ORCoordinator', label: 'or_coordinator', color: '#adcc77' },
        { key: 'WardNurse', label: 'ward_nurse', color: '#9d5c97' },
        { key: 'orthopedics', label: 'orthopedics', color: '#1cade4' },
        { key: 'neurosurgery', label: 'neurosurgery', color: '#135d9a' },
        { key: 'ent', label: 'ent', color: '#d9a80e' },
        { key: 'urology', label: 'urology', color: '#cd3c87' },
        { key: 'gynaecology', label: 'gynaecology', color: '#ad5c97' },
      ]}
    />
  );
}

export default TaskComplianceView;
