import { PushDevice, PushSystem } from './push-device';
import { PushNote } from './push-note';

/** Interface for implementations of push notification services */
export interface PushProviderInterface {
  /** Sends a push notification
   *
   * @param device The device to send to
   * @param note The message to send
   *
   * @returns true if send call was initiated, false if send call was not performed, throws exception in case of error
   */
  send(device: PushDevice, note: PushNote): Promise<boolean>;
  supportedSystems(): PushSystem[];
}

/* Master push implementation interface */
export interface PushInterface extends PushProviderInterface {
  createSecureId(): string;
}

export enum PushErrorType {
  Unknown = 'unknown',
  TokenInvalid = 'token_invalid',
}
export class PushError extends Error {
  constructor(type: PushErrorType, device?: PushDevice) {
    super(`PushError: type: ${type}, device: ${device}`);
    this.type = type;
    this.device = device;
  }

  readonly type: PushErrorType;
  readonly device?: PushDevice;
}
