import { FC } from 'react';

interface UnknownTypeProps {
  entityType: string;
}

export const UnknownType: FC<UnknownTypeProps> = (props) => {
  console.warn(
    `Component type ${props.entityType} is not registered in the factory. With the following props:`,
    props
  );
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: '#ff6b6b',
        color: '#fff',
        textAlign: 'center',
        padding: '2em',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
      }}
    >
      <div>
        <div
          style={{
            fontSize: '1.5em',
            fontWeight: 'bold',
            marginBottom: '0.5em',
          }}
        >
          Component Not Found
        </div>
        <div style={{ fontSize: '1.2em' }}>
          The element type
          <span
            style={{
              fontWeight: 'bold',
              marginLeft: '0.5em',
              marginRight: '0.5em',
              color: '#ffd700',
            }}
          >
            {props.entityType}
          </span>
          is not registered in the factory.
        </div>
      </div>
    </div>
  );
};

export default UnknownType;
