import { OperationSpec, OperationType } from '@sqior/js/operation';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { ValueObject } from '@sqior/js/data';
import { Logger } from '@sqior/js/log';

export const UserPairedPhonePath = 'paired-device';
export const UserPairedPhoneStatePath = UserPairedPhonePath;
export const UserPairedPhoneOpPath = UserPairedPhonePath;
export const PairingTokenType = 'pairing';

export const DeviceListPath = 'paired-device-list';
export const DeviceListStatePath = DeviceListPath;

export const DeletePairedPhonePath = 'delete-paired-phone';
export const DeletePairedPhoneOpPath = DeletePairedPhonePath;

export const RefreshTokenOperationPath = 'refresh-pairing-token';

export const UserDeviceListVMType = 'UserDeviceListVMType';

export const AnonymousToken = 'anonymous';

export const PairingFeaturePublicServerUrlPath = 'pairing-feature-public-server-url';
export const PairingPublicKeyStatePath = 'pairingKeyStatePath';
export const PairingPublicServerCustomerPath = 'pairing-public-server-customer-path';

export const PublicExposurePath = 'public-server-routing';

export type PairingToken = {
  sub: string;
  typ: string;
  name: string;
  cst: string;
  iss: string;
  grace: number;
};

export type SignedToken = PairingToken & {
  iat: number;
  exp: number;
};

export type QRToken = {
  otp: string;
  cst: string;
  iat: number;
};

export type UserDevice = {
  model?: string;
  manufacturer?: string;
  deviceName?: string;
};

export type UserDeviceListItem = {
  id: Entity;
  customDeviceName: string;
  genericDeviceName: string;
  lastConnected: number;
  pairedAt: number;
  active: boolean;
};

export type UserDeviceListVM = EntityHeader & {
  devices: UserDeviceListItem[];
};

export const EmptyUserDeviceList: UserDeviceListVM = {
  entityType: UserDeviceListVMType,
  devices: [],
};
export type PairingTokenData = { token: string };
export type QRTokenData = { token: string; customer: string; device?: UserDevice };

export function PairPhone(
  qrToken: string,
  customer: string,
  device?: UserDevice
): OperationSpec<QRTokenData> {
  const data: QRTokenData = {
    token: qrToken,
    customer,
  };
  if (device) data.device = device;
  return { type: OperationType.Add, path: UserPairedPhoneOpPath, data };
}

export function RefreshPairingToken(token: string): OperationSpec<PairingTokenData> {
  const data = {
    token,
  };
  return { type: OperationType.Add, path: RefreshTokenOperationPath, data };
}

export function RemovePairing(id: Entity): OperationSpec<ValueObject> {
  return {
    type: OperationType.Delete,
    path: DeletePairedPhoneOpPath,
    data: { id: 'not used', data: id },
  };
}

export function createDeviceNamePair(deviceEntity: UserDevice | undefined, fallback: string) {
  const device: UserDevice = {};
  Logger.debug(['Device: ', deviceEntity]);

  if (!deviceEntity)
    return {
      customName: fallback,
      genericName: fallback,
    };
  if (deviceEntity.deviceName) device.deviceName = deviceEntity.deviceName;
  if (deviceEntity.model) device.model = deviceEntity.model;
  if (deviceEntity.manufacturer) device.manufacturer = deviceEntity.manufacturer;

  if (Object.keys(device).length === 0)
    return {
      customName: fallback,
      genericName: fallback,
    };

  const model =
    device.model && device.manufacturer
      ? device.manufacturer + ' ' + device.model
      : device.manufacturer
      ? device.manufacturer.toLowerCase() === 'apple'
        ? 'iPhone'
        : 'Android'
      : fallback;
  const customName = device.deviceName ?? device.model ?? model;
  return {
    customName,
    genericName: model,
  };
}
