import { FC, ReactNode, Suspense, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import logo from './logo.png';

interface Props {
  children?: ReactNode;
}

export const LazyLoading: FC<Props> = ({ children }) => {
  return <Suspense fallback={<FallBack />}>{children}</Suspense>;
};

export default LazyLoading;

interface FallBackProps {
  relative?: boolean;
  backgroundColor?: 'primary';
}

export const FallBack: FC<FallBackProps> = ({ relative, backgroundColor }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 300); // Delay in milliseconds

    return () => clearTimeout(timer); // Clear the timer when the component unmounts
  }, []);

  const getBackgroundColor = () => {
    switch (backgroundColor) {
      case 'primary':
        return '#0A0F23';
      default:
        return 'rgba(0, 0, 0, 0.5)';
    }
  };

  if (!show) return null; // Don't render anything until the timer expires

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        position: relative ? 'relative' : 'fixed',
        top: 0,
        left: 0,
        backgroundColor: getBackgroundColor(),
        color: 'white',
        fontSize: '2rem',
      }}
    >
      <motion.img
        src={logo} // Make sure 'logo' is correctly imported or defined
        alt="Sqior Logo"
        style={{
          width: '100px',
          height: '100px',
        }}
        animate={{
          scale: [1, 1.1, 1], // Scales the image up to 10% and back to original size
        }}
        transition={{
          duration: 0.8, // Duration of one cycle of the animation
          ease: 'easeInOut',
          repeat: Infinity, // Repeat the animation indefinitely
          repeatType: 'loop',
        }}
      />
    </div>
  );
};
