import { MessageTelemetryPath } from '@sqior/viewmodels/telemetry';
import TelemetryViewArea from '../telemetry-view-area/telemetry-view-area';

/* eslint-disable-next-line */
export interface MessageTelemetryViewProps {}

export function MessageTelemetryView(props: MessageTelemetryViewProps) {
  return (
    <TelemetryViewArea
      path={MessageTelemetryPath}
      title="messages_by_day"
      values={[
        { key: 'tasks', label: 'tasks', color: '#1cade4' },
        { key: 'notifications', label: 'notifications', color: '#135d9a' },
        { key: 'escalations', label: 'escalations', color: '#d9a80e' },
        { key: 'chats', label: 'chats', color: '#cd3c87' },
      ]}
    />
  );
}

export default MessageTelemetryView;
