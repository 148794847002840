import { Entity, EntityHeader } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';
import { PersonSex } from '@sqior/viewmodels/person';
import { CaveInfoVM, CaveSeverity } from './cave-info-vm';
import { ColorCodes, Icons, TrafficLightState } from '@sqior/viewmodels/visual';
import { SpecialtyVM } from './specialty-vm';

export const PatientOverviewPath = 'patient-overview';
export const PagePatientOverviewType = 'page.patient-overview';

export const PatientInfoEntityType = 'PatientInfo';

export type PatientInfo = EntityHeader & {
  name: string;
  id?: string;
  dob?: string;
  sex?: PersonSex;
  age?: string;
  caseId?: string;
  spi?: number;
  spiColorCode?: ColorCodes;
  caveSeverity?: CaveSeverity;
  caveInfos?: CaveInfoVM[];
  insurance?: string;
  specialty?: SpecialtyVM;
};

export type PatientOverview = {
  info: PatientInfo;
  content?: Entity;
};

/* Command when a patient overview is selected or closed */

export const SelectPatientPath = joinPath(PatientOverviewPath, 'select-patient');

export function SelectPatient(overview: Entity): OperationSpec<Entity> {
  return { type: OperationType.Add, path: SelectPatientPath, data: overview };
}
export function ClosePatient(): OperationSpec<string> {
  return { type: OperationType.Delete, path: SelectPatientPath, data: 'patient' };
}

/** Standard button for patient specific overviews */
export const StdActionButtonVMType = 'StdActionButtonVM';
export type StdActionButtonVM = EntityHeader & {
  iconType: Icons;
  nameLong: string;
  nameShort: string;
  select: OperationSpec;
  iconTrafficLight?: string;
};
export function makeStdActionButtonVM(
  nameLong: string,
  nameShort: string,
  select: OperationSpec,
  iconType: Icons,
  iconTrafficLight?: TrafficLightState
): StdActionButtonVM {
  const res: StdActionButtonVM = {
    entityType: StdActionButtonVMType,
    nameLong,
    nameShort,
    select,
    iconType,
  };
  if (iconTrafficLight !== undefined) res.iconTrafficLight = iconTrafficLight;
  return res;
}

/** Base type for infos */
export type WorkflowInfoVM = EntityHeader & {
  type: 'long' | 'short';
  alwaysVisibile: boolean;
};

/** Standard infos for patient specific overviews */
export const StdInfoVMType = 'StdInfoVM';
export type StdInfoVM = WorkflowInfoVM & {
  iconType?: Icons;
  text: string;
};
export function makeStdInfoVM(
  text: string,
  alwaysVisibile: boolean,
  type: 'long' | 'short',
  iconType?: Icons
): StdInfoVM {
  const ret: StdInfoVM = { entityType: StdInfoVMType, text, alwaysVisibile, type };
  if (iconType) ret.iconType = iconType;
  return ret;
}
