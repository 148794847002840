import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinUrlPath } from '@sqior/js/url';

/** Path for app version update and confirmation */

export const AppVersionUpdatePath = 'app-version-update';
export const AppVersionUpdateConfirmationPostfix = 'confirm';
export const AppVersionUpdateConfirmationPath = joinUrlPath(
  AppVersionUpdatePath,
  AppVersionUpdateConfirmationPostfix
);

/** App update information */

export type AppVersionUpdateData = { description: string; instruction: string; force: boolean };

/* Set the app version and system */

export enum AppVersionSystem {
  iOS = 'ios',
  Android = 'android',
}
export type AppVersionData = { system: AppVersionSystem; version: string; model?: string };

export function AppVersionInfo(data: AppVersionData): OperationSpec<AppVersionData> {
  return { type: OperationType.Add, path: AppVersionUpdatePath, data: data };
}

/** Confirms the app version update */

export function ConfirmAppVersionUpdate(timestamp: number): OperationSpec<number> {
  return { type: OperationType.Add, path: AppVersionUpdateConfirmationPath, data: timestamp };
}
