import Cave from './cave/cave';
import { InterweaveExtContext } from './interweave-ext/interweave-ext';
import TagSpecialty, {
  TagIsolation,
  TagMonitoring,
  TagNursingComplexity,
  TagPriority,
} from './tags/tags';

export function UIBasePlugin(context: InterweaveExtContext) {
  context.interweaveFactory.register('cave', Cave);
  context.interweaveFactory.register('tagisolation', TagIsolation);
  context.interweaveFactory.register('tagmonitoring', TagMonitoring);
  context.interweaveFactory.register('tagpriority', TagPriority);
  context.interweaveFactory.register('tagspecialty', TagSpecialty);
  context.interweaveFactory.register('tagnursingcomplexity', TagNursingComplexity);
}
