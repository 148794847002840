import { transformPrimitives, Value, visit } from '@sqior/js/data';
import { Emitter } from '@sqior/js/event';
import { Temporary } from './temporary';

export class TemporaryObject {
  constructor(value: Value) {
    this.current = value;
    this.temps = {};
    this.count = 0;
    this.failed = false;
    this.change = new Emitter<[Value?]>();

    /* Search for temporaries in the value */
    visit(value, (value) => {
      if (typeof value === 'string') {
        const temp = Temporary.get(value);
        if (temp) this.temps[value] = temp;
      }
    });

    /* Listen for changes of the temporaries */
    for (const key in this.temps) {
      this.count++;
      this.temps[key].final.on((value) => {
        /* Do not do anything if this has failed */
        if (this.failed) return;
        /* If the final value is set, decrease the count of open temporaries - flush if at 0 */
        if (value) {
          this.count--;
          this.current = this.patch(this.current, this.temps);
          delete this.temps[key];
          this.change.emit(this.current);
        } else {
          this.failed = true;
          this.change.emit();
        }
      });
    }
  }

  private patch(value: Value, temps: Record<string, Temporary>) {
    /* Transform all strings of the object that represent a temporary */
    return transformPrimitives(value, (el) => {
      if (typeof el === 'string') {
        const temp = temps[el];
        return temp ? temp.current : el;
      } else return el;
    }) as Value;
  }

  get final() {
    return this.failed || this.count === 0;
  }

  current: Value;
  failed: boolean;
  private temps: Record<string, Temporary>;
  private count: number;

  readonly change: Emitter<[Value?]>;
}
