import { ORWorkflowTelemetryPath } from '@sqior/viewmodels/telemetry';
import TelemetryViewArea from '../telemetry-view-area/telemetry-view-area';
import styles from './orworkflow-telemetry-view.module.css';

/* eslint-disable-next-line */
export interface ORWorkflowTelemetryViewProps {}

export function ORWorkflowTelemetryView(props: ORWorkflowTelemetryViewProps) {
  return (
    <TelemetryViewArea
      path={ORWorkflowTelemetryPath}
      title="procedures_by_day"
      values={[
        { key: 'perfect', label: 'procedure_perfect', color: '#73e2f8' },
        { key: 'completed', label: 'procedure_completed', color: '#1cade4' },
        { key: 'pending', label: 'procedure_pending', color: '#135d9a' },
      ]}
    />
  );
}

export default ORWorkflowTelemetryView;
