import { useDynamicStateRaw } from '@sqior/react/state';
import styles from './telemetry-view-area.module.css';
import Plot from 'react-plotly.js';
import { Data, PlotType } from 'plotly.js';
import {
  SelectTelemetryCustomer,
  SelectTelemetrySubDivison,
  SelectTelemetryTimeRange,
  TelemetryViewModelData,
} from '@sqior/viewmodels/telemetry';
import { SelectControl, useTextResources } from '@sqior/react/uibase';
import { useContext } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { FallBack } from '@sqior/react/factory';

export interface TelemetryViewAreaProps {
  path: string;
  title: string;
  type?: string;
  values?: { key: string; label: string; color: string }[];
}

export const TelemetryCustomerOrder = [
  { key: '', label: 'all_customers', color: '#fff' },
  { key: 'UKSH', label: 'UKSH Kiel', color: '#1cade4' },
  { key: 'UKSH_HL', label: 'UKSH Lübeck', color: '#3c8df4' },
  { key: 'LMU', label: 'LMU', color: '#135d9a' },
  { key: 'UKW', label: 'UKW', color: '#137d7a' },
  { key: 'Erding', label: 'Erding', color: '#137d3c' },
  { key: 'KHM', label: 'Hann. Münden', color: '#336d6a' },
];

export function TelemetryViewArea(props: TelemetryViewAreaProps) {
  const textDict = useTextResources();
  const chart = useDynamicStateRaw<TelemetryViewModelData>(props.path);
  const dispatcher = useContext(OperationContext);
  /* Set up bar data */
  let data: Data[] | undefined;
  const colors: string[] = [];
  let customers: { label: string; select: string }[] | undefined;
  let selectedCustomer = 0;
  let sub: { label: string; select: string }[] | undefined;
  let selectedSub = 0;
  if (chart) {
    data = [];
    /* Add registered series */
    const registered = new Set<string>();
    for (const value of props.values ?? TelemetryCustomerOrder)
      if (chart.values[value.key]) {
        data.push({
          type: (props.type as PlotType) ?? 'bar',
          x: chart.labels,
          y: chart.values[value.key],
          name: textDict.get(value.label) || value.label,
          line: { width: 2 },
        });
        colors.push(value.color);
        registered.add(value.key);
      }
    /* Add remaining unregistered series */
    if (chart.selectedSub) {
      const unregistered: string[] = [];
      for (const key in chart.values) if (!registered.has(key)) unregistered.push(key);
      unregistered.sort();
      for (let i = 0; i < unregistered.length; i++) {
        const key = unregistered[i];
        data.push({
          type: (props.type as PlotType) ?? 'bar',
          x: chart.labels,
          y: chart.values[key],
          name: key,
          line: { width: 2 },
        });
        colors.push(
          'hsl(' +
            ((55 * i) % 360).toString() +
            ', ' +
            (80 - Math.floor(i / 6) * 5).toString() +
            '%, 50%)'
        );
      }
    }
    /* Set up customer data, if applicable */
    if (chart.customers) {
      customers = [];
      for (const value of TelemetryCustomerOrder) {
        const index = chart.customers.findIndex((c) => {
          return c === value.key;
        });
        if (index < 0) continue;
        if (chart.selectedCustomer === index) selectedCustomer = customers.length;
        customers.push({ label: value.label, select: value.key });
      }
    }
    /* Set up sub data, if applicable */
    if (chart.sub) {
      const subValues = [{ label: 'all_groups', key: '', color: '' }].concat(props.values ?? []);
      sub = [];
      for (const value of subValues) {
        const index = chart.sub.findIndex((c) => {
          return c === value.key;
        });
        if (index < 0) continue;
        if (chart.selectedSub === index) selectedSub = sub.length;
        sub.push({ label: value.label, select: value.key });
      }
    }
  }
  return (
    <div className={styles['container']}>
      {!data && <FallBack relative backgroundColor="primary" />}
      {data && !data.length && (
        <div className={styles['text']}>
          <span>{textDict.get('no_data')}</span>
        </div>
      )}
      {data && data.length && (
        <Plot
          className={styles['plot']}
          data={data}
          layout={{
            autosize: true,
            paper_bgcolor: '#00000000',
            plot_bgcolor: '#00000000',
            font: { color: '#fff' },
            title: { text: textDict.get(props.title), font: { size: 30 } },
            xaxis: { showgrid: false },
            yaxis: { linecolor: '#ffffff44', gridcolor: '#ffffff44' },
            colorway: colors,
            barmode: 'stack',
          }}
        />
      )}
      {sub && sub.length > 1 && selectedCustomer > 0 && (
        <SelectControl
          className={styles['sub']}
          data={{
            items: sub.map((c) => {
              return textDict.get(c.label) || c.label;
            }),
            selected: selectedSub,
          }}
          onSelect={(item) => {
            if (sub) dispatcher.start(SelectTelemetrySubDivison(props.path, sub[item].select));
          }}
        />
      )}
      {customers && customers.length > 1 && (
        <SelectControl
          className={styles['customers']}
          data={{
            items: customers.map((c) => {
              return textDict.get(c.label) || c.label;
            }),
            selected: selectedCustomer,
          }}
          onSelect={(item) => {
            if (customers)
              dispatcher.start(SelectTelemetryCustomer(props.path, customers[item].select));
          }}
        />
      )}
      {chart && chart.timeRanges.length > 1 && (
        <SelectControl
          className={styles['time-range']}
          data={{
            items: chart.timeRanges.map((entry) => {
              return textDict.get(entry.label);
            }),
            selected: chart.selectedTimeRange,
          }}
          onSelect={(item) => {
            dispatcher.start(SelectTelemetryTimeRange(props.path, chart.timeRanges[item].days));
          }}
        />
      )}
    </div>
  );
}

export default TelemetryViewArea;
