import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';

/* Telemetry view data */

export type TelemetryViewData = {
  labels: string[];
  values: Record<string, number[]>;
  customers?: string[];
  selectedCustomer?: number;
  sub?: string[];
  selectedSub?: number;
};
export type TelemetryTimeRange = { days: number; label: string };
export type TelemetryViewModelData = TelemetryViewData & {
  timeRanges: TelemetryTimeRange[];
  selectedTimeRange: number;
};

/** Session telemetry per customer */

export const SessionTelemetryPath = 'sesssion-telemetry';

/** Message types telemetry  */

export const MessageTelemetryPath = 'message-telemetry';
export const TaskComplianceTelemetryPath = 'message-telemetry-tasks';

/** ORWorkflow telemetry  */

export const ORWorkflowTelemetryPath = 'or-workflow-telemetry';
export const OperatedORsTelemetryPath = 'operated-ors-telemetry';

/** Operation to select customer */

export const SelectTelemetryCustomerSubPath = 'customer';
export function SelectTelemetryCustomer(path: string, customer: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(path, SelectTelemetryCustomerSubPath),
    data: customer,
  };
}

/** Operation to select sub-division */

export const SelectTelemetrySubDivisionSubPath = 'sub';
export function SelectTelemetrySubDivison(path: string, sub: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(path, SelectTelemetrySubDivisionSubPath),
    data: sub,
  };
}
/** Operation to select time range */

export const SelectTelemetryTimeRangeSubPath = 'time';
export function SelectTelemetryTimeRange(path: string, days: number): OperationSpec<number> {
  return {
    type: OperationType.Add,
    path: joinPath(path, SelectTelemetryTimeRangeSubPath),
    data: days,
  };
}
