import { BiChannel, MessageRecombination, Message } from '@sqior/js/message';
import { Bytes } from '@sqior/js/data';
import { isConnectionMessage } from '@sqior/js/session';
import { Logger } from '@sqior/js/log';
import { MessageEvent } from './websocketif';

export abstract class MessageReceiver extends BiChannel {
  protected constructor() {
    super();
    this.recombiner = new MessageRecombination();
  }

  protected recombiner: MessageRecombination;

  protected abstract handleInternalMessage(msg: Message): void;

  protected recvMessage(ev: MessageEvent) {
    try {
      // Get message pieces from socket => feed to recombiner => if final, do further message processing
      const msg = this.recombiner.handle(
        typeof ev.data === 'string' ? JSON.parse(ev.data) : new Bytes(ev.data)
      );
      if (msg) {
        if (isConnectionMessage(msg)) {
          this.handleInternalMessage(msg);
        } else {
          this.in.send(msg);
        }
      }
    } catch (e) {
      Logger.warn(
        ['Error in parsing message from socket, skipping message:', Logger.exception(e)],
        [
          'Error in parsing message:',
          JSON.stringify(ev),
          'from socket, skipping message:',
          Logger.exception(e),
        ]
      );
    }
  }
}
