import styles from './action-button.module.css';
import { useContext } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { Badge, ListItemIcon, ListItemText, Typography } from '@mui/material';
import SIcon from '../sicon/sicon';
import HighlightButton from '../highlight-button/highlight-button';
import { TrafficLightState } from '@sqior/viewmodels/visual';
import { StdActionButtonVM } from '@sqior/viewmodels/patient';

export type ActionButtonProps = StdActionButtonVM & {
  badgeContent?: number;
  short?: boolean;
  overflow?: boolean;
};

export function ActionButton({
  select,
  iconType,
  nameLong,
  nameShort,
  iconTrafficLight,
  short,
  overflow,
  badgeContent,
}: ActionButtonProps) {
  const dispatcher = useContext(OperationContext);

  const onClick = () => {
    dispatcher.start(select);
  };

  if (overflow) {
    return (
      <div className={styles['row']} onClick={onClick}>
        <ListItemIcon>
          <Badge
            variant={badgeContent ? 'standard' : 'dot'}
            invisible={!(badgeContent || iconTrafficLight)}
            badgeContent={badgeContent}
            slotProps={COMPONENT_PROPS(iconTrafficLight)}
          >
            <SIcon icon={iconType} size={30} />
          </Badge>
        </ListItemIcon>
        <ListItemText>
          <Typography variant="inherit">{nameLong}</Typography>
        </ListItemText>
      </div>
    );
  }

  return (
    <HighlightButton
      size="small"
      mode="tertiary"
      onClick={onClick}
      icon={
        <Badge
          variant={badgeContent ? 'standard' : 'dot'}
          invisible={!(badgeContent || iconTrafficLight)}
          badgeContent={badgeContent}
          slotProps={COMPONENT_PROPS(iconTrafficLight)}
        >
          <SIcon icon={iconType} size={24} />
        </Badge>
      }
    >
      <div className={styles['text']}>{short ? nameShort : nameLong}</div>
    </HighlightButton>
  );
}

export default ActionButton;

const BackgroundMap = new Map<string, string>([
  [TrafficLightState.Red, '#e14747'],
  [TrafficLightState.Green, '#3cd04e'],
  [TrafficLightState.Yellow, '#ffbb5c'],
]);
const COMPONENT_PROPS = (status?: string) => {
  return {
    badge: {
      style: {
        color: 'white',
        top: 5,
        right: 5,
        backgroundColor: status ? BackgroundMap.get(status) : undefined,
      },
    },
  };
};
